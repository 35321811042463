import './Container.css';
import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';

export const Container = forwardRef<HTMLDivElement, UI.Container.Props>(function Container(
  {children, className, fullWidth, mobilePadding, ...attributes},
  ref,
) {
  const classes = classNames('Container', className, {
    'narrow': !fullWidth,
    'full-width': Boolean(fullWidth),
    'mobile-padding': Boolean(mobilePadding),
  });

  return (
    <div {...attributes} className={classes} ref={ref}>
      {children}
    </div>
  );
});
