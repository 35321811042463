import {useContext} from 'react';
import {SlotContext} from '../contexts/SlotContext';

export function missingSlot() {
  console.warn(`Slot not found`);
}

export function useSlot(name: string) {
  return useContext(SlotContext)[name] ?? missingSlot;
}
