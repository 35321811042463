import {useContext, type ReactNode} from 'react';
import {SlotContext} from '../../contexts/SlotContext';
import type {Slots} from '../../types/Slots';

export interface SlotProviderProps {
  slots: Slots;
  children: ReactNode;
}

export function SlotProvider({slots, children}: SlotProviderProps) {
  const parentSlots = useContext(SlotContext);
  return <SlotContext.Provider value={{...parentSlots, ...slots}}>{children}</SlotContext.Provider>;
}
