import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';

export const Span = forwardRef<HTMLSpanElement, UI.Span.Props>(function Span(
  {
    '2xl:tone': xxlTone,
    'lg:tone': lgTone,
    'md:tone': mdTone,
    'sm:tone': smTone,
    'xl:tone': xlTone,
    children,
    className,
    fontAntialiased,
    fontFamily,
    fontStyle,
    fontVariantNumeric,
    fontWeight,
    textDecorationLine,
    size,
    tone,
    ...attributes
  },
  ref,
) {
  const classes = classNames('Span decoration-current', className, {
    '2xl:text-danger-11': xxlTone === 'danger',
    '2xl:text-gray-12': xxlTone === 'default',
    '2xl:text-info-11': xxlTone === 'info',
    '2xl:text-neutral-11': xxlTone === 'neutral',
    '2xl:text-success-11': xxlTone === 'success',
    '2xl:text-warning-11': xxlTone === 'warning',
    'diagonal-fractions': fontVariantNumeric === 'diagonal-fractions',
    'font-black': fontWeight === 'black',
    'font-bold': fontWeight === 'bold',
    'font-extrabold': fontWeight === 'extrabold',
    'font-extralight': fontWeight === 'extralight',
    'font-light': fontWeight === 'light',
    'font-medium': fontWeight === 'medium',
    'font-mono': fontFamily === 'mono',
    'font-normal': fontWeight === 'normal',
    'font-sans': fontFamily === 'sans',
    'font-semibold': fontWeight === 'semibold',
    'font-serif': fontFamily === 'serif',
    'font-thin': fontWeight === 'thin',
    'lg:text-danger-11': lgTone === 'danger',
    'lg:text-gray-12': lgTone === 'default',
    'lg:text-info-11': lgTone === 'info',
    'lg:text-neutral-11': lgTone === 'neutral',
    'lg:text-success-11': lgTone === 'success',
    'lg:text-warning-11': lgTone === 'warning',
    'lining-nums': fontVariantNumeric === 'lining-nums',
    'md:text-danger-11': mdTone === 'danger',
    'md:text-gray-12': mdTone === 'default',
    'md:text-info-11': mdTone === 'info',
    'md:text-neutral-11': mdTone === 'neutral',
    'md:text-success-11': mdTone === 'success',
    'md:text-warning-11': mdTone === 'warning',
    'normal-nums': fontVariantNumeric === 'normal-nums',
    'not-italic': fontStyle === 'normal',
    'oldstyle-nums': fontVariantNumeric === 'oldstyle-nums',
    'proportional-nums': fontVariantNumeric === 'proportional-nums',
    'slashed-zero': fontVariantNumeric === 'slashed-zero',
    'sm:text-danger-11': smTone === 'danger',
    'sm:text-gray-12': smTone === 'default',
    'sm:text-info-11': smTone === 'info',
    'sm:text-neutral-11': smTone === 'neutral',
    'sm:text-success-11': smTone === 'success',
    'sm:text-warning-11': smTone === 'warning',
    'stacked-fractions': fontVariantNumeric === 'stacked-fractions',
    'subpixel-antialiased': fontAntialiased === 'subpixel-antialiased',
    'tabular-nums': fontVariantNumeric === 'tabular-nums',
    'text-2xl': size === '2xl',
    'text-3xl': size === '3xl',
    'text-4xl': size === '4xl',
    'text-5xl': size === '5xl',
    'text-6xl': size === '6xl',
    'text-7xl': size === '7xl',
    'text-8xl': size === '8xl',
    'text-9xl': size === '9xl',
    'text-base': size === 'md' || size == null,
    'text-danger-11': tone === 'danger',
    'text-gray-12': tone === 'default' || tone == null,
    'text-info-11': tone === 'info',
    'text-lg': size === 'lg',
    'text-neutral-11': tone === 'neutral',
    'text-sm': size === 'sm',
    'text-success-11': tone === 'success',
    'text-warning-11': tone === 'warning',
    'text-xl': size === 'xl',
    'text-xs': size === 'xs',
    'xl:text-danger-11': xlTone === 'danger',
    'xl:text-gray-12': xlTone === 'default',
    'xl:text-info-11': xlTone === 'info',
    'xl:text-neutral-11': xlTone === 'neutral',
    'xl:text-success-11': xlTone === 'success',
    'xl:text-warning-11': xlTone === 'warning',
    antialiased: fontAntialiased === 'antialiased',
    italic: fontStyle === 'italic',
    ordinal: fontVariantNumeric === 'ordinal',
    underline: textDecorationLine === 'underline',
    overline: textDecorationLine === 'overline',
    'line-through': textDecorationLine === 'line-through',
    'no-underline': textDecorationLine === 'none',
    [((fontVariantNumeric as any[]) ?? []).join(' ')]: Array.isArray(fontVariantNumeric),
  });

  return (
    <span {...attributes} className={classes} ref={ref}>
      {children}
    </span>
  );
});
