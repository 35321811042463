import {type ReactNode, useState, Children} from 'react';
import {Slot} from '../components/Slot';

export function useRenderableSlot(children: ReactNode, name?: string) {
  return useState<ReactNode>(() => {
    let initialSlot = null;

    Children.forEach(children, (child) => {
      if (
        child &&
        typeof child === 'object' &&
        'type' in child &&
        child.type === Slot &&
        child.props.name === name
      ) {
        initialSlot = child.props.children;
      }
    });

    return initialSlot;
  });
}
