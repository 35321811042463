import './Card.css';
import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';
import {SlotProvider} from '../SlotProvider';
import {useRenderableSlot} from '@cindedi/ui/hooks/useRenderableSlot';

export const Card = forwardRef<HTMLDivElement, UI.Card.Props>(function Card(
  {children, className, shadow, 'md:shadow': mdShadow, ...attributes},
  ref,
) {
  const [TitleSlot, setTitleSlot] = useRenderableSlot(children, 'title');
  const [ActionSlot, setActionSlot] = useRenderableSlot(children, 'action');
  const classes = classNames('Card flex flex-col', {
    'shadow-md': Boolean(shadow),
    'md:shadow-md': Boolean(mdShadow),
    'md:shadow-none': mdShadow === false,
  });

  return (
    <SlotProvider slots={{title: setTitleSlot, action: setActionSlot}}>
      <div {...attributes} className={classes} ref={ref}>
        {(TitleSlot || ActionSlot) && (
          <div className="flex items-baseline justify-between text-base font-semibold leading-7 sm:truncate sm:tracking-tight text-shade-12">
            {TitleSlot && <div>{TitleSlot}</div>}
            {ActionSlot && <div>{ActionSlot}</div>}
          </div>
        )}
        <div className={className}>{children}</div>
      </div>
    </SlotProvider>
  );
});
